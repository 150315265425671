import React, { createContext, useContext, useEffect, useState, useCallback } from 'react'
import { navigate } from "gatsby"
import { useTheme }  from '~/contexts/ThemeContext'
import useShopCustomerHook from '~/utils/shop-customer'
import useAnalyticsHook from '~/utils/analytics'

const AccountContext = createContext()

export function AccountProvider({ children }) {
  const { customer } = useTheme()
  const { order_history } = useShopCustomerHook()
  const { ana_account } = useAnalyticsHook()

  // const { order_history } = useShopCustomerHook()
  // // const [view, setView] = useState('account')
  const [orders, setOrders] = useState([])
  const [order, setOrder] = useState(null)
  const [pg, setPg] = useState(null)

  const get_history = async () => {
    if (orders.length > 0) return 
    const order_arr = await order_history() 
    setOrders(order_arr)
  }

  const formatDate = (date) => {
    const obj = new Date(date)
    const new_date = obj.toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric"}) 
    const new_time = obj.toLocaleTimeString();

    return `${new_date} - ${new_time}`
  }

  const checkDate = (date) => {
    const order_date = new Date(date)
    const now = new Date()
    const one_year = new Date()
    one_year.setFullYear(now.getFullYear() - 1)

    return order_date > one_year
  }


  useEffect(() => {
    if (pg !== 'order' || orders.length < 1) return

    const url = new URL(window.location.href)
    const order_name = url.searchParams.get('n')

    if (order && order.includes(order_name)) return

    const curr_order = orders.find(x => x.name.includes(order_name) )
    setOrder(curr_order)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders])


  useEffect(() => {
    if (!pg) return

    if (!customer && sessionStorage.getItem('so')) {
      sessionStorage.removeItem('so')
      window.location = 'https://checkout.buckmason.com/account/logout'
      return
    }

    const is_stag = process.env.GATSBY_CURR_ENV === 'development'
    let auth_url = is_stag ? 'https://buckmason-rms.pima.io/auth' : 'https://orders.buckmason.com/auth'
    const loggedout = !customer || !customer?.full
    if (loggedout) {
      navigate(auth_url)
      return
    }

    if (pg === 'account' && (localStorage.getItem('ga_login') || localStorage.getItem('ga_register'))) {
      ana_account()
    }

    if (orders.length < 1) get_history()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer,pg])


  return (
    <AccountContext.Provider value={{ orders, setOrders, order, setOrder, pg, setPg, formatDate, checkDate }}>
      {children}
    </AccountContext.Provider>
  );
}

export function useAccount() {
  const context = useContext(AccountContext)
  if (context === undefined) {
    throw new Error('useAccount must be used within an AccountProvider')
  }
  return context
}

