import React, { useState, useEffect, useRef } from "react"
import { Link, navigate } from "gatsby"
import { useLocation } from '@reach/router'
import { useTheme } from "~/contexts/ThemeContext"
import { useHeaderQuery } from "~/hooks/useHeaderQuery"
import useAnalyticsHook from '~/utils/analytics'
import { inViewPartial, notBlank } from '~/helpers/helpers'
import PropTypes from "prop-types"

import HeaderSubmenu from '~/components/panels/head-submenu'
import Logo from '~/components/icons/logo'
import IconSearch from '~/components/icons/search'
import IconMenu from '~/components/icons/menu'
import IconAccount from '~/components/icons/account'
import IconCart from '~/components/icons/cart'

import { aria_btn } from "~/styles/app.module.scss"
import * as styles from "~/styles/header.module.scss"

const Header = ({ setShowMobileMenu }) => {
  const { gender, setGender, setSearch, cart, setCartOpen, customer, custfavs } = useTheme()

  const location = useLocation();
  const { ga_nav_click } = useAnalyticsHook()

  const is_preview = location.pathname.includes('/preview/')

  const { headers, journals } = useHeaderQuery()
  const topMenu = headers.find( x => x.name === 'top' )
  const subMenus = headers.filter( x => x.name === 'new men' || x.name === 'new women')
  const journal_sub = journals.find(x => x.handle === 'head-journal')
  const banner = headers.find( x => x.name === 'HeaderBanner' )
  if (journal_sub) {
    journal_sub['name'] = 'journal'
    subMenus.push(journal_sub)
  }
  
  const [openPanel, setOpenPanel] = useState(false)
  const [currGend, setCurrGend] = useState(false)
  const [cartQty, setCartQty] = useState(false)
  const [isLogin, setIsLogin] = useState(false)
  const [pos, setPos] = useState(0)

  const head_ele = useRef(null)
  const head_el = `.${styles.nd_head}`

  const bannerRef = useRef(null)

  const is_stag = process.env.GATSBY_CURR_ENV === 'development'
  const auth_url = is_stag ? 'https://buckmason-rms.pima.io/auth' : 'https://orders.buckmason.com/auth'

  const gender_journal = (name) => {
    if (name === 'journal') return false
    const gender = `${name.replace('new ','')}s`
    return journals.find(x => x.handle === `${gender}-menu-blog`)
  }

  const handleScroll = (e) => {
    if (!head_ele.current) return

    const new_pos = Math.round(window.pageYOffset)
    const is_up = new_pos <= 0 || new_pos < window.position.last
    const diff = Math.abs(window.position.last - new_pos)
    window.position.last = new_pos

    const head = head_ele.current
    const head_h = Math.round(head.getBoundingClientRect().height)

    const is_full = head.hasAttribute('full')
    const plpbar = document.querySelector('[plp-bar]')
    const filtpanel = document.querySelector('[filters-wr]')

    let change_pos = pos

    if ( is_up ) {
      if (new_pos <= 0) {
        change_pos = 0
      } else {
        change_pos = pos + diff
        if (change_pos >= 0) change_pos = 0
      }
    } else {
      setOpenPanel(false)
      change_pos = pos - diff
      if (Math.abs(change_pos) >= head_h) change_pos = (head_h + 0.5) * -1
    }


    if (change_pos !== pos) {
      setPos(change_pos)
      head.style.transform = `translate(0, ${change_pos}px)`;

      if (plpbar || filtpanel) check_plp(change_pos)
    }

    if (is_full && Math.abs(change_pos) < head_h) check_full()
  }
  
  const check_plp = (change_pos) => {
    if (!head_ele.current) return

    const head = head_ele.current
    const plpbar = document.querySelector('[plp-bar]')

    if ( plpbar ) {
      const head_height = Math.round(head.getBoundingClientRect().height)
      plpbar.style.top = `${change_pos + head_height}px`
    }
  }
  
  const check_full = () => {
    if (!head_ele.current) return
      
    const el = head_ele.current
    let blocks = document.querySelectorAll('[full-block]')

    if (blocks.length < 1) return

    const block = Array.from(blocks).find(block => {
      const style = window.getComputedStyle(block)
      return style.display !== 'none' && style.visibility !== 'hidden' && style.opacity !== '0'
    })

    if (!block) return

    let in_view = false
    const block_view = inViewPartial(block)

    if (window.scrollY < 20 || block_view) in_view = true

    el.setAttribute('full', in_view ? 'true' : 'false')
    if (el.getAttribute('mfull')) el.setAttribute('mfull', in_view ? 'true' : 'false')
  }

  const deskHover = () => {
    document.querySelectorAll(`${head_el} nav a:not([site-menu]), ${head_el} nav button:not([site-menu]), main, footer`).forEach((x) => {
      x.removeEventListener('mouseenter', deskHover)
    })
    setOpenPanel(false)
  }

  const handleClick = ( gender ) => {
    if (gender === 'journal') {
      navigate('/blogs/journal')
      return
    }

    if ( typeof window !== "undefined" ) {
      localStorage.setItem('homeg', gender)
      setGender(gender)
      if ( window.innerWidth < 900 ) {
        setOpenPanel(openPanel === gender ? false : gender)
      } else if ( gender ) {
        setOpenPanel(false)
        if (!is_preview && location.pathname !== '/') navigate('/')
      }
    }
  }

  const handleCart = (evt_name) => {
    setCartOpen(true);
    gaClick(evt_name, `/cart`)
  }

  const handleKey = (e, gender) => {
    if (e.key === 'Enter' || e.key === 'Space') {
      e.preventDefault()
      setOpenPanel(openPanel === gender ? false : gender)
      if (gender !== 'journal') {
        localStorage.setItem('homeg', gender)
        setGender(gender)
      }
      setTimeout(()=>{ document.querySelector('[data-panel][aria-hidden="false"] a').focus()}, 20)
      window.addEventListener('keydown', panelKey);
    }
  }

  const panelKey = (e) => {
    if (e.key === "Escape" || e.key === "ArrowUp") {
      const opanel = document.querySelector('[data-panel][aria-hidden="false"]')
      const panel_gender = opanel ? opanel.getAttribute('data-panel') : false
      e.preventDefault()
      setOpenPanel(false)
      if (panel_gender) document.querySelector(`[aria-controls="${panel_gender}"]`).focus()
      window.removeEventListener('keydown', panelKey);
    }
  }

  const handleEnter = ( status ) => {
    if ( typeof window !== "undefined" && window.innerWidth > 900 ) {
      setOpenPanel(status)
      document.querySelector(head_el).addEventListener('mouseenter', deskHover)
      document.querySelectorAll(`${head_el} nav a:not([site-menu]), ${head_el} nav button:not([site-menu]), main, footer`).forEach((x) => {
        x.addEventListener('mouseenter', deskHover)
      })
    }
  }

  const gaClick = (name, url) => {
    const obj = {
      hierarchy: `top > ${name}`,
      text: name,
      url: url
    }
    if (!is_preview) ga_nav_click(obj)
  }

  const gend_bar_click = (e, gender) => {
    e.preventDefault()
    localStorage.setItem('homeg', gender)
    setGender(gender)
    if (is_preview) return 
    if (location.pathname !== '/') navigate('/')
  }

  useEffect(() => {
    if (typeof document === 'undefined') return
      
    window.position = {last: Math.round(window.pageYOffset), up: false}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!bannerRef.current) return

    const toggleBanner = () => {
      if (bannerRef.current) {
        bannerRef.current.style.opacity = bannerRef.current.style.opacity === '0' ? '' : '0';
      }
    };

    const intervalId = setInterval(toggleBanner, 7000);
    return () => clearInterval(intervalId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bannerRef.current])

  useEffect(() => {
    if (!customer) {
      setIsLogin(false)
      return
    }
    if (customer.full) setIsLogin(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer])

  useEffect(() => {
    const cart_lines = cart && cart.lines.edges.length > 0 ? cart.lines.edges.length : 0
    const qty = cart_lines ? cart.lines.edges.reduce((total, x) => total + x.node.quantity, 0) : 0
    setCartQty(qty)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  useEffect(() => {
    if (typeof window === 'undefined') return

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  useEffect(() => {
    if (is_preview || location.pathname === '/') setCurrGend(gender)
  }, [gender, location.pathname, is_preview])


  return (
    <header className={styles.nd_head} id={'header'} ref={head_ele}>
      <Link to="#MainContent" className="in-page-link visually-hidden skip-link">
        Skip to content
      </Link>
      <Link to="/pages/accessibility" className="in-page-link visually-hidden skip-link" >
        Click to view our Accessibility Statement or contact us with accessibility-related questions
      </Link>

      <div className={styles.banner}>
        {banner ? (
          <>
            {banner.default}
            {notBlank(banner.custom) && (
              <div ref={bannerRef} className={styles.banner} dangerouslySetInnerHTML={{ __html: banner.custom }} />
            )}
          </>
        ) : (
          <>Shipping On Us Over $150 & 365-Day Returns</>
        )}
      </div>

      <nav className={[styles.nd_headContent, styles.nd_headTop, styles.mobhead].join(' ')} >
        <div className={styles.mobhead_sec}>
          <button 
            className={[styles.nd_headTop_btn].join(' ')}
            aria-label="Open Main Menu dialog"
            onClick={(e)=>{ 
              setShowMobileMenu(true);
              gaClick('Mobile Menu', `/?mobile-menu`)
            }}
          >
            <IconMenu icon_classes={`head_icon icon-hamberger ${styles.hamberger}`}></IconMenu>
          </button>
        </div>

        <Link to="/" className={styles.headLogo_link} aria-label="Go to homepage">
          <div className={styles.headLogo_svg}>
            <Logo logo_class={styles.svgLogo}></Logo>
          </div>
        </Link>

        <div className={[styles.mobhead_sec, styles.mobhead_right].join(' ')}>
          <button
            className={[styles.nd_headTop_btn].join(' ')}
            data-uipop-btn="search_new" 
            aria-label="Open Search dialog" 
            onClick={(e)=>{
              setSearch(true);
              gaClick('Mobi: Search', `/search`)
            }}
          >
            <IconSearch icon_classes={styles.nicon}></IconSearch>
          </button>
          {(!!custfavs && localStorage.getItem('ward')) && (
            <Link
              to={'/preview/wardrobe'}
              className={[styles.nd_headTop_btn, styles.wardbtn].join(' ')} 
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                <path d="M231.1,111.8c.8-48.6,40.5-87.8,89.3-87.8s89.3,40,89.3,89.3-6.1,49.9-33,68.4L47.1,
                  406c-38.4,26-20,86.1,26.4,86.1h494.4c46.4,0,64.8-60.1,26.4-86.1l-273.6-185.2"/>
              </svg>
            </Link>
          )}
          <button
            className={`${styles.nd_headTop_btn} ${cartQty > 0 ? styles.nd_cartOn : ''}`}
            data-uipop-btn="cart" 
            aria-label="Open cart dialog" 
            onClick={(e)=> handleCart('Mobi: Cart')}
          >
            <IconCart icon_classes={styles.nicon}></IconCart>
            {cartQty > 0 && (
              <div className={styles.cart_qty}>{cartQty}</div>
            )}
          </button>
        </div>
        <div className={styles.mobhead_gend}>
          <Link 
            className={`${styles.gend_link} ${currGend === 'men' ? styles.curr : ''}`} 
            to={'/'} 
            onClick={(e) => gend_bar_click(e,'men')}
          >
            Men
          </Link>
          <Link 
            className={`${styles.gend_link} ${currGend === 'women' ? styles.curr : ''}`} 
            to={'/'} 
            onClick={(e) => gend_bar_click(e,'women')}
          >
            Women
          </Link>
        </div>
      </nav>

      <nav className={[styles.nd_headContent, styles.nd_headTop, styles.deskhead].join(' ')}>
        <div className={styles.nd_headMain}>
          <Link to="/" className={styles.headLogo_link} aria-label="Go to homepage">
            <div className={styles.headLogo_svg}>
              <Logo logo_class={styles.svgLogo}></Logo>
            </div>
          </Link>
          {topMenu.menu.map( (item, index) => {
            const targets = item.name.toLowerCase() === 'the journal' ? 'journal' : item.name.toLowerCase()
            const is_open = openPanel === targets

            return(
              item.url === 'headsp_main-btn' ? (
                <button 
                  key={`headtop-${index}`} 
                  className={[styles.nd_headTop_btn].join(' ')}
                  site-menu=""
                  aria-expanded={is_open}
                  aria-controls={`[data-panel="panel-${targets}"]`} 
                  onKeyDown={(e)=> handleKey(e, targets) }
                  onClick={(e) => {
                    handleClick(targets);
                    gaClick(item.name, `/?g=${targets}`);
                    setOpenPanel(false)
                  }}
                  onMouseEnter={(e) => handleEnter(targets)}
                >
                  {item.name}
                </button>
              ) : (
                <Link 
                  key={`headtop-${index}`} 
                  to={item.url} 
                  className={[styles.nd_headTop_btn].join(' ')}
                  onClick={(e)=> gaClick(item.name, item.url)}
                >
                   {item.name}
                </Link>
              )
            )
          })}
          <button
            className={[aria_btn, styles.nd_headTop_btn].join(' ')}
            data-uipop-btn="search_new" 
            aria-label="Open Search dialog" 
            onClick={(e)=>{
              setSearch(true);
              gaClick('Search', `/search`)
            }}
          >
            <IconSearch icon_classes={styles.nicon}></IconSearch>
          </button>
        </div>
        <div className={styles.nd_headAux}>
          <Link 
            to="/pages/our-stores" 
            className={[styles.nd_headTop_btn].join(' ')}
            onClick={(e)=> gaClick('Our Stores', '/pages/our-stores')}
          >
            Our Stores
          </Link>
          {(!!custfavs && localStorage.getItem('ward')) && (
            <Link
              to={'/preview/wardrobe'}
              className={[styles.nd_headTop_btn, styles.wardbtn].join(' ')} 
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                <path d="M231.1,111.8c.8-48.6,40.5-87.8,89.3-87.8s89.3,40,89.3,89.3-6.1,49.9-33,68.4L47.1,
                  406c-38.4,26-20,86.1,26.4,86.1h494.4c46.4,0,64.8-60.1,26.4-86.1l-273.6-185.2"/>
              </svg>
            </Link>
          )}
          {isLogin ? (
            <Link 
              to={isLogin ? '/account' : auth_url}
              className={[styles.nd_headTop_btn, `${isLogin ? styles.logged_in : ''}`].join(' ')} 
              login-link="" 
              aria-label="Your account"
              onClick={(e)=> gaClick('Account', '/account')}
            >
              <IconAccount icon_classes={styles.nicon}></IconAccount>
            </Link>
          ) : (
            <a 
              href={isLogin ? '/account' : auth_url}
              className={[styles.nd_headTop_btn, `${isLogin ? styles.logged_in : ''}`].join(' ')} 
              login-link="" 
              aria-label="Your account"
              onClick={(e)=> gaClick('Account', '/account')}
            >
              <IconAccount icon_classes={styles.nicon}></IconAccount>
            </a>
          )}
          <button
            className={`${aria_btn} ${styles.nd_headTop_btn} ${cartQty > 0 ? styles.nd_cartOn : ''}`}
            data-uipop-btn="cart" 
            aria-label="Open cart dialog" 
            onClick={(e)=> handleCart('Cart')}
          >
            <IconCart icon_classes={styles.nicon}></IconCart>
            {cartQty > 0 && (
              <div className={styles.cart_qty}>{cartQty}</div>
            )}
          </button>
        </div>
      </nav>
      <div 
        className={`${styles.nd_headPanels} ${!!openPanel ? styles.open : ''}`} 
      >
        {subMenus.map( (x, index) => 
          <HeaderSubmenu 
            key={`headsub-${index}`} 
            panel={openPanel} 
            obj={x} 
            journal_menu={gender_journal(x.name)}
          /> 
        )}
      </div>

    </header>
  )
}

export default Header

Header.propTypes = {
  panel: PropTypes.string,
  sub: PropTypes.bool
}

