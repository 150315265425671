import React, { useEffect, useState, useRef } from 'react';
// import { injectSpeedInsights } from "@vercel/speed-insights"
import { useLocation } from '@reach/router'
import { useTheme } from '~/contexts/ThemeContext'
import { AccountProvider } from '~/contexts/AccountContext'
import QuickContext from '~/contexts/QuickContext'
import useAnalyticsHook from '~/utils/analytics'
import useShopCustomerHook from '~/utils/shop-customer'
import useCartApi from '~/utils/use-cart'
import useTestFunnelHook from '~/utils/test-funnel'
import Geoloc from '~/utils/geoloc'

// import PageTrans from '~/components/shared/page-trans'
import Header from '~/components/Header'
import MobileMenu from '~/components/panels/mobile-menu'
import Footer from '~/components/Footer'
import Cart from '~/components/panels/cart'
import CartNotif from '~/components/panels/cart-notif'
import Search from '~/components/panels/search'
import StoresPanel from '~/components/panels/stores-panel'
import Sizing from '~/components/pdp/sizing'
import GiftCard from '~/components/pdp/giftCard'
import LoginWarn from '~/components/shared/login-warn'

import '~/styles/app.scss';
import * as styles from "~/styles/app.module.scss"

export const Layout = ({ children, pageContext }) => {
  const { getGeo } = Geoloc()
  const location = useLocation();

  const { cart, cartOpen, mobileMenu, setMobileMenu, isMobile, isTouches, customer,
  storesOpen, quick, search, showSizing, setShowSizing, showGiftCard, 
  setPdpVariant, setFreeze, setLocate, showLoginWarn } = useTheme()

  const { ga_page, page_send, elev_customer } = useAnalyticsHook()
  const { check_refresh } = useShopCustomerHook()
  const { addDiscount } = useCartApi()
  const { fnnl_page } = useTestFunnelHook()

  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [testo, setTesto] = useState(false)

  const mainRef = useRef(null)

  const pathname = location.pathname
  let pageType = 'page' // default

  const policies = ['/pages/terms','/pages/privacy','/pages/customer-data','/pages/accessibility']

  if (pathname === '/') {
    pageType = 'home'
  } else if (pathname.includes('/preview/')) {
    pageType = 'preview'
  } else if (pathname.includes('/collections/')) {
    pageType = 'collection'
  } else if (pathname.includes('/account')) {
    pageType = 'account'
  } else if (pathname === '/blogs/journal') {
    pageType = 'blog'
  } else if (pathname.includes('/feature/') || pathname.includes('/blogs/journal/')) {
    pageType = 'article'
  } else if (pathname.includes('/products/') && pathname.includes('gift')) {
    pageType = 'giftCard'
  } else if (policies.includes(pathname)) {
    pageType = 'policy'
  } else if (pathname.includes('/products/')) {
    pageType = 'product'
  }

  const checkSavedLoc = async () => {
    if (typeof localStorage === "undefined") return
    
    const savd = localStorage.getItem('locate')
    const locarr = savd ? savd.split('|') : []
    let savd_time = locarr[0]
    let savd_loc = locarr[1] ? JSON.parse(locarr[1]) : false

    if (savd_loc && savd_time < (Date.now() - 600000)) {
      const geo = await getGeo(true, savd_loc)
      setLocate(geo)
    }
  }

  const checkBrowser = () => {
    const el = document.querySelector("html")
    el.setAttribute('is-m', isMobile ? 'true' : 'false') 
    isTouches ? el.setAttribute('is-t','true') : el.removeAttribute('is-t')
  }

  useEffect(() => {
    fnnl_page()
    let other_page = !location.pathname.includes('/products') && !location.pathname.includes('/collections') && location.pathname !== '/'
    if (pageType === 'preview') other_page = false

    if (!location.pathname.includes('/products')) setPdpVariant(false)

    // the one thing from Layout props
    // the one thing from Layout props
    // the one thing from Layout props
    // the one thing from Layout props
    // the one thing from Layout props
    
    page_send(pageType);
    if (other_page && typeof document !== 'undefined') {
      ga_page()
      elev_customer()
    }

    if (typeof window !== 'undefined') {
      const params = new URLSearchParams(window.location.search)
      if (!cart && params.get('discount')) sessionStorage.setItem('autodis', params.get('discount'))
      if (cart && params.get('discount')) addDiscount(cart.id, params.get('discount'))
    }

    // setTimeout(() => {
    //   if (mainRef.current) mainRef.current.style.opacity = 1
    // }, 100)
    
    checkBrowser()
    checkSavedLoc()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  useEffect(() => {
    checkBrowser()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile])

  useEffect(() => {
    const loggedout = !customer || !customer?.full || !customer.check_expire
    if (loggedout) return
    check_refresh()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer])

  useEffect(() => {
    if (showMobileMenu) {
      setMobileMenu(true)
      return
    }
    setMobileMenu(false)
    setFreeze(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMobileMenu])


  return (
    <AccountProvider>
      <Header setShowMobileMenu={setShowMobileMenu} />

      {(mobileMenu) && (
        <MobileMenu setShowMobileMenu={setShowMobileMenu} />
      )}

      <main ref={mainRef}>
        {children}
        {/* <PageTrans>
        </PageTrans> */}
      </main>

      {(cartOpen && cartOpen !== 'notif') && (
        <Cart />
      )}

      {(cartOpen && cartOpen === 'notif') && (
        <CartNotif />
      )}

      {quick && (
        <QuickContext quick_data={quick} />
      )}

      {search && (
        <Search />
      )}

      {storesOpen && (
        <StoresPanel variant={storesOpen} />
      )}

      {showSizing && (
        <Sizing guide={showSizing} onClose={setShowSizing} />
      )}

      {showGiftCard && (
        <GiftCard />
      )}

      {showLoginWarn && (
        <LoginWarn />
      )}

      <Footer />
    </AccountProvider>
  )
}

// Layout.propTypes = {
//   children: PropTypes.node.isRequired,
// }

export default Layout








