exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-account-addresses-jsx": () => import("./../../../src/pages/account/addresses.jsx" /* webpackChunkName: "component---src-pages-account-addresses-jsx" */),
  "component---src-pages-account-auth-jsx": () => import("./../../../src/pages/account/auth.jsx" /* webpackChunkName: "component---src-pages-account-auth-jsx" */),
  "component---src-pages-account-jsx": () => import("./../../../src/pages/account.jsx" /* webpackChunkName: "component---src-pages-account-jsx" */),
  "component---src-pages-account-login-jsx": () => import("./../../../src/pages/account/login.jsx" /* webpackChunkName: "component---src-pages-account-login-jsx" */),
  "component---src-pages-account-order-jsx": () => import("./../../../src/pages/account/order.jsx" /* webpackChunkName: "component---src-pages-account-order-jsx" */),
  "component---src-pages-account-recover-jsx": () => import("./../../../src/pages/account/recover.jsx" /* webpackChunkName: "component---src-pages-account-recover-jsx" */),
  "component---src-pages-account-register-jsx": () => import("./../../../src/pages/account/register.jsx" /* webpackChunkName: "component---src-pages-account-register-jsx" */),
  "component---src-pages-account-reset-jsx": () => import("./../../../src/pages/account/reset.jsx" /* webpackChunkName: "component---src-pages-account-reset-jsx" */),
  "component---src-pages-blogs-journal-blog-post-handle-jsx": () => import("./../../../src/pages/blogs/journal/{BlogPost.handle}.jsx" /* webpackChunkName: "component---src-pages-blogs-journal-blog-post-handle-jsx" */),
  "component---src-pages-blogs-journal-jsx": () => import("./../../../src/pages/blogs/journal.jsx" /* webpackChunkName: "component---src-pages-blogs-journal-jsx" */),
  "component---src-pages-cart-jsx": () => import("./../../../src/pages/cart.jsx" /* webpackChunkName: "component---src-pages-cart-jsx" */),
  "component---src-pages-collections-collection-handle-jsx": () => import("./../../../src/pages/collections/{Collection.handle}.jsx" /* webpackChunkName: "component---src-pages-collections-collection-handle-jsx" */),
  "component---src-pages-feature-feature-post-handle-jsx": () => import("./../../../src/pages/feature/{FeaturePost.handle}.jsx" /* webpackChunkName: "component---src-pages-feature-feature-post-handle-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-pages-accessibility-jsx": () => import("./../../../src/pages/pages/accessibility.jsx" /* webpackChunkName: "component---src-pages-pages-accessibility-jsx" */),
  "component---src-pages-pages-affiliates-jsx": () => import("./../../../src/pages/pages/affiliates.jsx" /* webpackChunkName: "component---src-pages-pages-affiliates-jsx" */),
  "component---src-pages-pages-buck-mason-knitting-mills-jsx": () => import("./../../../src/pages/pages/buck-mason-knitting-mills.jsx" /* webpackChunkName: "component---src-pages-pages-buck-mason-knitting-mills-jsx" */),
  "component---src-pages-pages-careers-jsx": () => import("./../../../src/pages/pages/careers.jsx" /* webpackChunkName: "component---src-pages-pages-careers-jsx" */),
  "component---src-pages-pages-customer-data-request-form-jsx": () => import("./../../../src/pages/pages/customer-data-request-form.jsx" /* webpackChunkName: "component---src-pages-pages-customer-data-request-form-jsx" */),
  "component---src-pages-pages-faq-jsx": () => import("./../../../src/pages/pages/faq.jsx" /* webpackChunkName: "component---src-pages-pages-faq-jsx" */),
  "component---src-pages-pages-gift-card-balance-jsx": () => import("./../../../src/pages/pages/gift-card-balance.jsx" /* webpackChunkName: "component---src-pages-pages-gift-card-balance-jsx" */),
  "component---src-pages-pages-our-story-jsx": () => import("./../../../src/pages/pages/our-story.jsx" /* webpackChunkName: "component---src-pages-pages-our-story-jsx" */),
  "component---src-pages-pages-privacy-jsx": () => import("./../../../src/pages/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-pages-privacy-jsx" */),
  "component---src-pages-pages-sitemap-jsx": () => import("./../../../src/pages/pages/sitemap.jsx" /* webpackChunkName: "component---src-pages-pages-sitemap-jsx" */),
  "component---src-pages-pages-store-handle-jsx": () => import("./../../../src/pages/pages/{Store.handle}.jsx" /* webpackChunkName: "component---src-pages-pages-store-handle-jsx" */),
  "component---src-pages-pages-terms-jsx": () => import("./../../../src/pages/pages/terms.jsx" /* webpackChunkName: "component---src-pages-pages-terms-jsx" */),
  "component---src-pages-pages-wholesale-jsx": () => import("./../../../src/pages/pages/wholesale.jsx" /* webpackChunkName: "component---src-pages-pages-wholesale-jsx" */),
  "component---src-pages-pos-jsx": () => import("./../../../src/pages/pos.jsx" /* webpackChunkName: "component---src-pages-pos-jsx" */),
  "component---src-pages-preview-blog-jsx": () => import("./../../../src/pages/preview/blog.jsx" /* webpackChunkName: "component---src-pages-preview-blog-jsx" */),
  "component---src-pages-preview-home-jsx": () => import("./../../../src/pages/preview/home.jsx" /* webpackChunkName: "component---src-pages-preview-home-jsx" */),
  "component---src-pages-preview-test-jsx": () => import("./../../../src/pages/preview/test.jsx" /* webpackChunkName: "component---src-pages-preview-test-jsx" */),
  "component---src-pages-preview-wardrobe-jsx": () => import("./../../../src/pages/preview/wardrobe.jsx" /* webpackChunkName: "component---src-pages-preview-wardrobe-jsx" */),
  "component---src-pages-products-product-handle-jsx": () => import("./../../../src/pages/products/{Product.handle}.jsx" /* webpackChunkName: "component---src-pages-products-product-handle-jsx" */),
  "component---src-pages-products-vint-product-handle-jsx": () => import("./../../../src/pages/products/{VintProduct.handle}.jsx" /* webpackChunkName: "component---src-pages-products-vint-product-handle-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-vintage-vint-collect-handle-jsx": () => import("./../../../src/pages/vintage/{VintCollect.handle}.jsx" /* webpackChunkName: "component---src-pages-vintage-vint-collect-handle-jsx" */)
}

