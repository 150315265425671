import React, { createContext, useState, useContext, useEffect, useMemo } from 'react'
import { useTheme } from '~/contexts/ThemeContext'
import useInventoryHook from '~/utils/get-inventory'
import useAnalyticsHook from '~/utils/analytics'
import useRecommendHook from '~/utils/recommends'
import useLastViewsHook from '~/utils/last-views'
import PdpProduct from '~/utils/pdp-product'

const ProductContext = createContext()

export function ProductProvider({ children, productData, isQuick=false }) {
  const { 
    setupProduct, checkCta, checkLow, checkOneSize,
    checkAnyStores, setupVariants, whichCntry, storesNear
  } = PdpProduct()

  const product = useMemo(() => {
    return setupProduct(productData)
  }, [productData, setupProduct])

  const { is_gift, csoon, final_sale, outfits, outfills } = product

  const initvariants = useMemo(() => {
    return setupVariants(product.variants, is_gift, false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product.id, product.variants, is_gift])

  const { 
    pdpVariant, setPdpVariant, setFreeze, setQuick, setSearch, 
    setCartOpen, setShowGiftCard, setShowSizing, pdpInv, shiplog, 
    locate, setLocate, shipPref, setShipPref, storesData, setShowSticky, 
    setShowStickyCta, isSHM
  } = useTheme()

  const { ana_pdp } = useAnalyticsHook()
  const { getRecs } = useRecommendHook()
  const { getinv } = useInventoryHook()
  const { setLasts } = useLastViewsHook()

  const [variants, setVariants] = useState(initvariants)
  const [prodInv, setProdInv] = useState(null)

  const [attributes, setAttributes] = useState({})
  const [showHemInfo, setShowHemInfo] = useState(false)
  const [blockPickup, setBlockPickup] = useState(false)
  const [recProds, setRecProds] = useState(false)
  const [wasRedirect, setWasRedirect] = useState(false)
  const [shipCheck, setShipCheck] = useState({ship: true, pickup: true})
  const [checkVarParam, setCheckVarParam] = useState(false)

  const [availStoreName, setAvailStoreName] = useState(null)
  const [isPickup, setIsPickup] = useState(false)
  const [showIntlBtn, setShowIntlBtn] = useState(false)
  const [isLow, setIsLow] = useState(product.low && product.low <= 25 ? product.low : null)

  const [showCta, setShowCta] = useState(true)
  const [showBis, setShowBis] = useState(false)
  const [showAfterp, setShowAfterp] = useState(false)
  const [showShipInfo, setShowShipInfo] = useState(false)
  const [showOutfits, setShowOutfits] = useState(false)

  const [nearInstock, setNearInstock] = useState([])
  const [farInstock, setFarInstock] = useState([])
  const [currStore, setCurrStore] = useState(null)
  const [anyStores, setAnyStores] = useState([])
  const [showStoresPanel, setShowStoresPanel] = useState(false)
 

  const setForGiftCard = () => {
    setShipCheck({ship: true, pickup: false})
    setShowCta(true)
    setShowAfterp(false)
    setShowBis(false)
    setShowShipInfo(false)
    setIsLow(false)
  }

  const checkOutfits = () => {
    if (is_gift) return 
    const is_test = (typeof sessionStorage !== 'undefined') && sessionStorage.getItem('outftest')
    if (!is_test && product.gender === 'u') return

    setShowOutfits( (outfits && outfits.length > 0) || (is_test && outfills && outfills.length > 0) )
  }

  const formatVariants = (inv=false) => {
    const pinv = inv || prodInv
    const new_vars = setupVariants(product.variants, is_gift, pinv)
    setVariants(new_vars)
    return new_vars
  }

  const onVariantSelect = (id) => {
    const new_size = variants.find( x => x.id === parseInt(id) )
    if (!new_size) return
    new_size.prod_shop_id = product.shop_id
    new_size.gender = product.gender

    if (!isQuick) window.history.replaceState({}, null, `/products/${product.handle}?variant=${id}`)
    setPdpVariant(new_size)

    if (product.product_line?.sztyp) {
      const size_type = localStorage.getItem('sztyp') ? JSON.parse(localStorage.getItem('sztyp')) : {}
      if (size_type[product.product_line?.sztyp] !== new_size.title) {
        size_type[product.product_line?.sztyp] = new_size.title
        localStorage.setItem('sztyp', JSON.stringify(size_type))
      }
    }
  }

  const onSelectHem = (attr, hem_data) => {
    if ( attr !== false ) {
      setAttributes({attributes: [{key: hem_data.code, value: attr.toString()}]})
      setBlockPickup(true)
      setAvailStoreName(null)
      return
    }
    setBlockPickup(false)
  }

  const setIntl = (state) => {
    setLocate({
      ...locate,
      shipIntl: state
    })
  }

  const onSetInstore = (store_name) => {
    setAvailStoreName(!isPickup ? null : store_name)
  }

  const onSwitchDelivery = (type, isUser=true) => {
    setIsPickup(type === 'pickup')
    if (isUser) setShipPref(type)
    if (type === 'ship') {
      onSetInstore(null)
    }
  }

  const checkPickupOpts = () => {
    if (!pdpVariant) return
    if (blockPickup) onSwitchDelivery('ship', false)

    let currship = !isPickup ? 'ship' : 'pickup'
    const wh_combo = pdpVariant && (!!pdpVariant.inv.DW || !!pdpVariant.inv['BL - Ecommerce'])
    const ship_avail = !isSHM ? !!pdpVariant.ship : wh_combo

    if (pdpVariant.available && ship_avail && pdpVariant.pickup) {
      if (shipPref !== currship) onSwitchDelivery(shipPref, false)
      return
    }

    let newship = currship
    if (pdpVariant.available && !pdpVariant.pickup && ship_avail) newship = 'ship'
    if (pdpVariant.available && !ship_avail && pdpVariant.pickup) newship = 'pickup'
    if (newship !== currship) onSwitchDelivery(newship, false)
  }

  const onStoresData = async () => {
    const storesobj = await storesNear(storesData, product.gender, pdpVariant)
    setNearInstock(storesobj.nears)
    setCurrStore(storesobj.store)
    setFarInstock(storesobj.others)

    if (locate?.currStores?.length > 0) {
      const currStore = storesData.flatMap(x => x.stores)
                    .filter(x => !!x.pickup_enabled)
                    .find(x => x.short_name === locate.currStores[0])

      let gend_check = false
      if (locate?.currStores?.length > 0 && product.gender !== 'u' && locate?.storeGend) {
        // const loc_gend = locate.storeGend.toLowerCase().includes('women') ? 'w' : 'm'
        if (product.gender === locate.storeGend) gend_check = true
      }
      if (currStore && gend_check) {
        setIsPickup(true)
        setAvailStoreName(currStore.pima_title)
      }
    }
  }

  const checkShipInfo = () => {
    if (!final_sale) {
      setShowShipInfo(false)
      return
    }
    if (final_sale) {
      setShowShipInfo(true)
      return
    }
    setShowShipInfo( (!csoon && showCta) && (locate?.shipIntl || locate?.country !== 'US') )
  }

  const get_recs = async () => {
    const recs = await getRecs(product.mid, product.product_line.mid)
    setRecProds(recs)
  }

  useEffect(() => {
    if (!isQuick) setPdpVariant(false)
    setLasts(product)
    if (sessionStorage.getItem('plRedirect')) {
      setWasRedirect(true)
      sessionStorage.removeItem('plRedirect')
    }
    setShowSticky(false)
    setShowStickyCta(false)
    setFreeze(false)
    if (!isQuick) setQuick(false)
    setSearch(false)
    setCartOpen(false)
    setShowGiftCard(false)
    setShowSizing(false)
    checkOutfits()
    getinv(product.pl_pima, product.pl_colors) 
    if (!recProds) get_recs()

    if (sessionStorage.getItem('cypress_test')) {
      window.product = product
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (is_gift) {
      setForGiftCard()
      return
    }
    if (csoon) {
      setShowCta(false)
      setShowBis(true)
      return
    }
    if (!variants) return

    const cta_vis = checkCta(variants)

    setShowCta(cta_vis)
    setShowBis(!cta_vis)
    setShowAfterp(cta_vis)
    checkShipInfo()
    checkPickupOpts()
    setAnyStores(checkAnyStores(prodInv))


    setIsLow(checkLow(variants))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdpVariant, prodInv, variants])

  useEffect(() => {
    if (!(storesData.length > 0 && locate)) return
    onStoresData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storesData, locate, pdpVariant])

  useEffect(() => {
    if (!locate) return

    const isIntl = locate?.shipIntl && whichCntry() !== 'US'
    if (isIntl && prodInv) formatVariants()

    setShowIntlBtn((!csoon && showCta) && isIntl)
    checkShipInfo()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prodInv, locate, showCta, csoon])

  useEffect(() => {
    if (!is_gift && !prodInv) return
    if (checkVarParam) return

    // after pageload and inv is set, check for a variant param to
    // set selected variant if direct link, or if PDP has same sizes
    // as last select same either from saved preference or last pdp

    const url = new URL(window.location.href);
    const variantId = url.searchParams.get('variant')

    if (variantId) onVariantSelect(variantId)

    let curr_size = false

    if (!variantId) {

      // check for saved preference to select a size if title in variants
      let last_sztyp = false
      // if (product.product_line?.sztyp && localStorage.getItem('sztyp')) {
      //   let size_type = JSON.parse(localStorage.getItem('sztyp'))
      //   size_type = size_type[product.product_line?.sztyp]
      //   const has_size = variants.find(x=> x.title === size_type)
      //   if (has_size) last_sztyp = has_size
      // }

      // check last pdp size for preference if present
      if (pdpVariant) {
        curr_size = variants.find(x=> x.title === pdpVariant.title)
        if (curr_size) onVariantSelect(curr_size.id)
      }
      if (!is_gift && !pdpVariant && last_sztyp) {
        const wh_combo = (!!last_sztyp?.inv.DW || !!last_sztyp?.inv['BL - Ecommerce'])
        const ship_avail = !isSHM ? !!last_sztyp.ship : wh_combo
        if (last_sztyp?.available && (ship_avail || last_sztyp?.pickup)) onVariantSelect(last_sztyp.id)
      }
    }

    // if product is one size, size that size
    if (!pdpVariant && variants.length === 1 && checkOneSize(variants[0].title)) {
      const curr_size = variants[0]
      onVariantSelect(curr_size.id)
    }
    setCheckVarParam(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prodInv])


  useEffect(() => {
    if (!checkVarParam) return

    // send analytics only after page load & variant param check
    // then on any variant change
    ana_pdp(product, pdpVariant)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdpVariant, checkVarParam])

  useEffect(() => {
    if (!isPickup) {
      setAvailStoreName(null)
      return
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPickup])

  useEffect(() => {
    // get the product inv from pline pdpInv to set in context
    // and initial setup of variants in state

    if (is_gift) {
      const g_vars = setupVariants(product.variants, is_gift, {})
      setVariants(g_vars)
      return
    }
    if (prodInv) return

    const pinv = (pdpInv && pdpInv?.find(x => x.id === product.shop_id)) || false
    if (!pinv) return
    pinv.variants = formatVariants(pinv)
    setProdInv(pinv)

    if (shiplog) {
      console.log(pinv)
    }

    if (sessionStorage.getItem('cypress_test')) {
      window.variants = pinv.variants
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdpInv])

  useEffect(() => {
    if (!isQuick) return
    const pinv = (pdpInv && pdpInv?.find(x => x.id === product.shop_id)) || false
    if (!pinv) return
    pinv.variants = formatVariants(pinv)
    setProdInv(pinv)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productData])


  return (
    <ProductContext.Provider value={{ 
      product, prodInv, wasRedirect, showHemInfo, setShowHemInfo, blockPickup, recProds, shipCheck, availStoreName,
      isPickup, isLow, showCta, showBis, showAfterp, showOutfits, showShipInfo, onVariantSelect, 
      onSelectHem, attributes, showIntlBtn, setIntl, onSetInstore, onSwitchDelivery, isQuick, setIsPickup,
      anyStores, variants, nearInstock, farInstock, currStore, setCurrStore, showStoresPanel, setShowStoresPanel,
      setAvailStoreName
    }}>
      {children}
    </ProductContext.Provider>
  );
}

export function useProduct() {
  return useContext(ProductContext)
}