import React, { useState, useEffect } from "react"
import { useProduct } from '~/contexts/ProductContext'
import { jnstyls } from '~/helpers/helpers'

import { IconChevRight } from '~/components/icons/chevrons'

import { aria_btn } from "~/styles/app.module.scss"
import * as styles from "~/styles/stores/store_panel.module.scss"


const PdpStore = ({ store, selectStore, regionOpen }) => {
  const { currStore } = useProduct()

  const [open, setOpen] = useState(false)

  return (
    <div className={styles.shop}>

      <button 
        className={jnstyls([aria_btn, styles.select])}
        aria-pressed={currStore?.short_name === store.short_name ? 'true' : 'false'} 
        onClick={(e)=> selectStore(store, true)}
      >
        <div className={styles.indicate}>
          <div className={styles.box}></div>
        </div>
        <div className={styles.title} dangerouslySetInnerHTML={{ __html: store.title }} />
        <div className={styles.dist}>
          {(store.hasOwnProperty('distance') && store.distance) ? `${store.distance}mi` : ''}
        </div>
      </button>
      <button 
        className={jnstyls([aria_btn, styles.detail_btn])} 
        aria-pressed={open ? 'true' : 'false'} 
        onClick={(e)=> setOpen(!open)}
      >
        Details
      </button>
      
      <div className={styles.infos} aria-hidden={open ? 'false' : 'true'}>
        <div className={styles.details}>
          {store.address_line_1 && (
            <div className={styles.detail}>
              <div>
                <p>
                  {store.address_line_1 && (
                    <>
                      {store.address_line_1}
                      <br/>
                    </>
                  )}
                  {store.address_line_2 && (
                    <>
                      {store.address_line_2}
                      <br/>
                    </>
                  )}
                  {store.address_city && `${store.address_city}, ${store.address_state} ${store.address_zip}`}
                </p>
                {store.phone && (
                  <>
                    <a href={`tel:{store.phone.replaceAll('.', '').replaceAll('-', '')`}>
                      {store.phone}
                    </a>
                    <br/>
                  </>
                )}
              </div>
            </div>
          )}
          {store.hours && (
            <div className={styles.detail} dangerouslySetInnerHTML={{ __html: store.hours }} />
          )}
        </div>
        <div className={styles.info}>
          {(!store.placeholder && store.gmaps) && (
            <div className={styles.gdirs}>
              <a href={`https://www.google.com/maps/dir/?api=1&destination=${store.gmaps}`} target="_blank" rel="noreferrer">
                Map
              </a>
            </div>
          )}
        </div>
        
      </div>

    </div>
  )
}



const PdpRegion = ({ regionData, selectStore, showOpen }) => {
  const [open, setOpen] = useState(showOpen)

  let inStock = regionData.stores.length

  useEffect(() => {
    setOpen(showOpen)
  }, [showOpen])


  return (
    <div className={styles.region} >
      <div className={styles.region_head}>
        <div className={styles.title}>
          <button 
            className={`${aria_btn} ${styles.title_btn}`}
            aria-label="Toggle this region's stores" 
            onClick={(e)=> {setOpen( !open )}} 
          >
            {regionData.region}
            {(!open && inStock) && (
              <span className={styles.region_instock}>
                In-stock at {inStock} store{inStock > 1 ? 's' : ''}
              </span>
            )}
          </button>
        </div>
        <div className={styles.region_hide}>
          <button 
            className={`${aria_btn} ${styles.hide_btn} ${open ? styles.collapse : ''}`}
            aria-label="Toggle this region's stores" 
            onClick={(e)=> {setOpen( !open )}} 
          >
            <IconChevRight icon_classes={''} />
          </button>
        </div>
      </div>

      <div className={styles.stores} aria-expanded={open ? 'true' : 'false'} aria-hidden={open ? 'false' : 'true'}>
        {regionData.stores.length > 0 && regionData.stores.map((store, index) => 
          <PdpStore 
            key={store.title} 
            store={store} 
            selectStore={selectStore} 
            regionOpen={showOpen}
          />
        )}
      </div>
    </div>
  )
}

export { PdpRegion, PdpStore }

