import React, { useState, useEffect } from "react"
import { useLocation } from '@reach/router'
import { Link } from "gatsby"
import { useTheme } from '~/contexts/ThemeContext'
import { secColorBg, hashCode, jnstyls } from '~/helpers/helpers'


import { aria_btn } from "~/styles/app.module.scss"
import * as styles from "~/styles/shared/swatches.module.scss"

const SwatchGroup = ({ prod_handle, color_group, trim=false, onInteraction, gaInteract=false, colorClick=false }) => {
  const { pdpInv, pdpVariant, quick, isSHM } = useTheme()
  const location = useLocation()

  const [pdp, setPdp] = useState(false);

  const num = color_group.length
  const color_arr = trim ? color_group.slice(0,trim) : color_group

  const handleEnter = (name) => {
    if (!name) onInteraction(false)

    onInteraction(name[0]);
    if (name[1]) {
      const testimg = new Image();
      testimg.src = name[1];
    }
  }

  const handleClick = (handle, action) => {
    if (gaInteract) gaInteract(action)
    if (colorClick) colorClick(handle)
  }

  const checkInv = (shop_id) => {
    if (trim || !(pdpInv && pdpVariant.title) ) return ''
    const prod = pdpInv.find(x => x.id === shop_id)
    if ( !prod || !prod.variants ) return ''

    const color_var = prod.variants.find(x => x.title === pdpVariant.title)
    if ( !color_var ) return styles.oos 
    const fulfill = prod.fulfill[pdpVariant.title]

    const wh_combo = (color_var.inv.DW || color_var.inv['BL - Ecommerce'] || false)
    const ship_avail = !isSHM ? fulfill?.ship : wh_combo
    
    if ( !fulfill ) return ''
    return color_var?.available && (ship_avail || fulfill?.pickup) ? '' : styles.oos 
  }

  useEffect(() => {
    setPdp( location.pathname.includes('/products/') )
  }, [location])
  

  return (
    <div 
      className={jnstyls([styles.swatches, !trim ? styles.pdp_swatches : '', (!pdp && !quick) ? styles.cs : ''])}
      role="menu" 
      tabIndex={!trim ? 0 : -1} 
      aria-labelledby={!trim ? `ar_label-select_${color_arr[0].handle.includes('venice') ? 'venice' : 'core'}` : undefined}
    >
      {color_arr.map( (color, index) => {
        const is_pdp_color = color.handle === prod_handle
        const sec_color = color.second_color_hex
        const inline_css = `
          .scolor-${hashCode(color.handle)}:after {
            background-image: ${secColorBg( sec_color ) };
          }
        `
        const whites = ['#fff','#ffffff','#fafafa'] 
        const is_white = whites.includes(color.hex_color)

        let classes = `${styles.nswatch} ${checkInv(color.shop_id)}`
        if (is_pdp_color) classes += ` ${styles.scurr}`
        if (sec_color) classes += ` ${styles.scolor} scolor-${hashCode(color.handle)}`

        return (
          <div key={`${hashCode(prod_handle)}${hashCode(color.handle)}${index}`}>
            {sec_color && (
              <style dangerouslySetInnerHTML={{ __html: inline_css }} />
            )}
            {pdp ? (
              <>
                {is_pdp_color  ? (
                  <div
                    className={classes}
                    style={{backgroundColor: color.hex_color, border: is_white ? '1px solid #999' : '0'}} 
                    tabIndex="-1"
                    role="menuitem" 
                    aria-current={is_pdp_color ? 'true' : 'false'}
                    aria-label={`${is_pdp_color ? `${color.name} currently selected` : `Select ${color.name}`}`}
                    onMouseEnter={(e) => handleEnter([color.name,color.image])}
                    onMouseLeave={(e) => handleEnter(false)}
                  >
                  </div>
                ) : (
                  <Link
                    className={classes}
                    to={`/products/${color.handle}`}
                    style={{backgroundColor: color.hex_color, border: is_white ? '1px solid #999' : '0'}} 
                    tabIndex="-1"
                    role="menuitem"  
                    aria-current={is_pdp_color ? 'true' : 'false'}
                    aria-label={`${is_pdp_color ? `${color.name} currently selected` : `Select ${color.name}`}`}
                    onMouseEnter={(e) => handleEnter([color.name,color.image])}
                    onMouseLeave={(e) => handleEnter(false)}
                  >
                  </Link>
                )}
              </>
            ) : (
              <button
                className={`${aria_btn} ${classes}`}
                style={{backgroundColor: color.hex_color, border: is_white ? '1px solid #999' : '0'}} 
                role="menuitem"  
                aria-current={is_pdp_color ? 'true' : 'false'}
                aria-label={`${is_pdp_color ? `${color.name} currently selected` : `Select ${color.name}`}`}
                onMouseEnter={(e) => handleEnter([color.name,color.image])}
                onMouseLeave={(e) => handleEnter(false)}
                onClick={(e)=> handleClick(color.handle, 'tile click: swatch')}
              />
            )}
          </div>
        )
      })}
      {trim && num > 8 && (
        <div className={styles.colors_more}>
          <button 
            className="aria_btn" 
            aria-label="See all color options, opens a dialog" 
            onClick={(e)=> handleClick(prod_handle, 'tile click: more colors')}
          >
            + {num - 8} More
          </button>
        </div>
      )}
    </div>
  );
};

export default SwatchGroup