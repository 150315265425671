import { useTheme } from '~/contexts/ThemeContext'
import QlReq from '~/utils/ql-req'
import { deShopId, reShopId, isStag } from '~/helpers/helpers'

const useInventoryHook = () => {
  const { pdpInv, setPdpInv } = useTheme()

  const getinv = async ( pima_id, pl_colors ) => {
    if ( pima_id === 99998 || pima_id === 99999 || (pdpInv && pdpInv.pima_id === pima_id) ) return

    const new_inv = await invj(pl_colors)

    const formatted_inv = Object.keys(new_inv).map(k => {
      let v = new_inv[k]
      if (!v ) {
        return {
          pima_id: pima_id,
          id: k.replace('p',''),
          inventory: [],
          filters: {
            locations: [],
            size: []
          },
          fulfill: {}
        }
      }
      const variants = v.variants.edges.map( (x) => { 
        const variant = {
          id: parseInt(x.node.id.replace('gid://shopify/ProductVariant/','')),
          title: x.node.title,
          available: x.node.availableForSale,
          inv: x.node.mPimaVarInv ? JSON.parse(x.node.mPimaVarInv.value) : false
        }
        return variant 
      })

      let inventory = v.mpInv ? JSON.parse(v.mpInv.value) : []
      Object.keys(inventory).forEach(k => {
        inventory[k] = inventory[k].split(',').filter(x => x !== '')
      })

      let fulfill = v.mpInvF ? JSON.parse(v.mpInvF.value) : {}

      let locations = []
      if (inventory) {
        Object.keys(inventory).forEach(k => {
          if (inventory[k].length > 0) locations.push(k) 
        })
      }
      let sizes = Object.keys(inventory).map(k => inventory[k]).flat().filter(x => !!x)
      sizes = [...new Set(sizes)]

      const obj = {
        pima_id: pima_id,
        id: deShopId(v.id),
        inventory: inventory,
        filters: {
          locations: locations,
          size: sizes
        },
        variants: variants,
        fulfill: fulfill
      }
      return obj
    })



    setPdpInv(formatted_inv)
  }

  const quickinv = async ( pima_id, shop_id ) => {

    const new_inv = await invj({standard: [{shop_id: shop_id}], venice_wash: []})

    const formatted_inv = Object.keys(new_inv).map(k => {
      let v = new_inv[k]
      if (!v ) {
        return {
          pima_id: pima_id,
          id: k.replace('p',''),
          inventory: [],
          filters: {
            locations: [],
            size: []
          },
          fulfill: {}
        }
      }
      const variants = v.variants.edges.map( (x) => { 
        const variant = {
          id: parseInt(x.node.id.replace('gid://shopify/ProductVariant/','')),
          title: x.node.title,
          available: x.node.availableForSale,
          shop_avail: x.node.availableForSale,
          inv: x.node.mPimaVarInv ? JSON.parse(x.node.mPimaVarInv.value) : false
        }
        return variant 
      })

      let inventory = v.mpInv ? JSON.parse(v.mpInv.value) : []
      Object.keys(inventory).forEach(k => {
        inventory[k] = inventory[k].split(',').filter(x => x !== '')
      })

      let fulfill = v.mpInvF ? JSON.parse(v.mpInvF.value) : {}

      let locations = []
      if (inventory) {
        Object.keys(inventory).forEach(k => {
          if (inventory[k].length > 0) locations.push(k) 
        })
      }
      let sizes = Object.keys(inventory).map(k => inventory[k]).flat().filter(x => !!x)
      sizes = [...new Set(sizes)]

      const obj = {
        pima_id: pima_id,
        id: deShopId(v.id),
        inventory: inventory,
        filters: {
          locations: locations,
          size: sizes
        },
        variants: variants,
        fulfill: fulfill
      }
      return obj
    })

    return formatted_inv.find(x => x.id === shop_id)
  }

  const parseinv = (prod_inv, pima_id=false, mid=false) => {
    const variants = prod_inv.variants.edges.map( (x) => { 
      const variant = {
        id: parseInt(x.node.id.replace('gid://shopify/ProductVariant/','')),
        title: x.node.title,
        available: x.node.availableForSale,
        inv: x.node.mPimaVarInv ? JSON.parse(x.node.mPimaVarInv.value) : false
      }
      return variant 
    })

    let inventory = prod_inv.mpInv ? JSON.parse(prod_inv.mpInv.value) : []
    Object.keys(inventory).forEach(k => {
      inventory[k] = inventory[k].split(',').filter(x => x !== '')
    })

    let fulfill = prod_inv.mpInvF ? JSON.parse(prod_inv.mpInvF.value) : {}

    let locations = []
    if (inventory) {
      Object.keys(inventory).forEach(k => {
        if (inventory[k].length > 0) locations.push(k) 
      })
    }
    let sizes = Object.keys(inventory).map(k => inventory[k]).flat().filter(x => !!x)
    sizes = [...new Set(sizes)]

    const obj = {
      id: deShopId(prod_inv.id),
      inventory: inventory,
      filters: {
        locations: locations,
        size: sizes
      },
      variants: variants,
      fulfill: fulfill
    }

    if (pima_id) obj.pima_id = pima_id
    if (mid) obj.mid = mid

    return obj
  }

  const qltxt = ( shopify_id ) => {
    return `p${shopify_id.replace('gid://shopify/Product/','')}: product(id:"${shopify_id}") {
      id\n
      availableForSale\n
      variants(first: 30){
        edges{
          node{
            availableForSale\n
            id\n
            title\n
            mPimaVarInv: metafield(namespace: "pima", key: "inventory") {value}
          }
        }
      }\n
      mpInv: metafield(namespace: "pima", key: "inventory") {value}\n
      mpInvF: metafield(namespace: "pima", key: "fulfillable") {value}
    }`
  }

  const invj = async ( pl_colors ) => {
    let ids = pl_colors.standard.map(x => x.shop_id).concat(pl_colors.venice_wash.map(x => x.shop_id))
    ids = ids.map(x => reShopId(x))

    let query = '{'
    ids.forEach( x => query += qltxt(x) )
    query += '}'

    const req = await QlReq.post(query, {})
    return req
  }

  const multiInv = async ( shop_ids ) => {
    let ids = shop_ids.map(x => reShopId(x))

    let query = '{'
    ids.forEach( x => query += qltxt(x) )
    query += '}'

    const req = await QlReq.post(query, {})
    return req
  }

  const whVarCheck = (prod_shopid, variant) => {
    if (!variant) return false
    const prod = pdpInv.find(x => x.id === prod_shopid)
    if (!prod || !prod.inventory) return false

    const var_fulfill = prod.fulfill[variant.title]
    const var_ship = var_fulfill?.ship || false

    const wh_short = isStag() ? 'WH' : 'DW'
    return var_ship && prod.inventory[wh_short].includes(variant.title)
  }

  const fulfillVarCheck = (prod_shopid, variant) => {
    const prod = pdpInv.find(x => x.id === prod_shopid)
    if (!prod) return {ship: true, pickup: true}
    const var_fulfill = prod.fulfill[variant.title] 

    return var_fulfill
  }

  const shmVarCheck = (prod_shopid, variant, no_wh=true) => {
    if (!variant) return false
    const prod = pdpInv.find(x => x.id === prod_shopid)
    if (!prod || !prod.inventory) return false

    let instocks = []

    if (no_wh) {
      const whs = ['WH', 'DW', 'BL - Ecommerce']
      instocks = [
        ...new Set(
          Object.entries(prod.inventory)
            .filter(([k]) => whs.includes(k))
            .flatMap(([, values]) => values)
        ),
      ]
    } else {
      instocks = [...new Set(Object.values(prod.inventory).flat())]
    }

    return instocks.includes(variant.title)
  }

  const posInv = (upd_prods, inv_obj) => {
    const formatted_inv = Object.keys(inv_obj).map(k => {
      let v = inv_obj[k]
      if (!v) return false
      const shop_id = deShopId(v.id)
      const prod = upd_prods.find(x => x.shop_id === shop_id)

      let prod_inv = parseinv(v, prod.pl_id, prod.mid)

      prod_inv.variants.forEach(x => {
        const fulfill = prod_inv.fulfill[x.title]
        x.ship = fulfill?.ship || false
        x.pickup = fulfill?.pickup || false
      })

      return prod_inv
    })

    return formatted_inv.filter(x => !!x)
  }

  return { 
    getinv, quickinv, whVarCheck, shmVarCheck, fulfillVarCheck, 
    multiInv, posInv 
  }

}

export default useInventoryHook;








