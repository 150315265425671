const Geoloc = () => {

  const getGeo = async (noreq=false, saved=false) => {
    let newLoc = null
    if (noreq !== 'skipip') {
      newLoc = await getFenix()
      const testzip = await mapObj(newLoc.zip)
      if (testzip.fail && newLoc.coords) {
        let get_gzip = await geoZip(newLoc.coords)
        newLoc.zip = get_gzip
      }
    }

    let cft = {}
    if (noreq === 'skipip' || (!noreq && !newLoc)) newLoc = await getGoogle()
    cft = !newLoc?.country ? await getCFdata() : {}

    const cntry = !!saved?.usrCountry ? saved.usrCountry : newLoc?.country || cft?.loc || null
    const notUS = cntry !== 'US'

    let obj = {
      zip: newLoc?.zip || false, 
      country: newLoc?.country || cft?.loc || false, 
      ip: newLoc?.ip || cft?.ip || false,
      currStores: saved?.currStores || [],
      usrZip: saved?.usrZip || null,
      usrCountry: saved?.usrCountry || null,
      pdpStore: saved?.pdpStore || null,
      shipIntl: saved?.shipIntl || notUS,
      storeGend: saved?.storeGend || false,
      reg: `${newLoc?.city} ${newLoc?.region}`,
      coords: newLoc?.coords
    }

    return obj
  }

  const getFenix = async () => {
    return new Promise((resolve, reject) => { 
      fetch('https://ipapi.co/json/?key=Jaio0rg93RS6UPxzEXB19vvw4NKUb292e9pLeiDOjsfnSjyLc5', {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
      })
      .then(req => {
        if (req.status !== 200) resolve(false)
        return req.json()
      })
      .then(data => {
        resolve({
          zip: data.postal, 
          city: data.city, 
          region: data.region, 
          country: data.country, 
          ip: data.ip, 
          coords: [data.latitude, data.longitude]
        })
      })
    })
  }

  const getGoogle = async () => {
    const coords = await request()
    var gcoords = new window.google.maps.LatLng(coords.latitude, coords.longitude);
    return new Promise((resolve, reject) => { 
      new window.google.maps.Geocoder().geocode({'latLng': gcoords}, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK && results[0]) {
          let testZip = results[0].address_components.find( x => x.types.includes("postal_code"))
          const testCountry = results[0].address_components.find( x => x.types.includes("country"))
          resolve({zip: testZip.long_name, country: testCountry.short_name})
        } else {
          console.log("Geocoder failed due to: " + status);
          resolve({zip: false, country: false})
        }
      })
    })
  }

  const getCFdata = () => {
    return new Promise((resolve, reject) => {
      fetch('https://www.cloudflare.com/cdn-cgi/trace')
      .then((response) => response.text())
      .then((data) => {
        const cfdata = data.trim().split('\n').reduce((obj, pair) => {
          pair = pair.split('='); // Split the pair into key and value
          obj[pair[0]] = pair[1]; // Assign the value to the object
          return obj; // Return the object
        }, {})
        resolve(cfdata)
      })
    })
  }

  const geoZip = (coords) => {
    var gcoords = new window.google.maps.LatLng(coords[0], coords[1]);
    return new Promise((resolve, reject) => {
      new window.google.maps.Geocoder().geocode({'latLng': gcoords}, (res, status) => {
        const has_zip = res && res.find(item => item.address_components.find( x => x.types.includes("postal_code")) )
        let g_zip
        if (has_zip) {
          g_zip = has_zip.address_components.find( x => x.types.includes("postal_code"))
          g_zip = g_zip.long_name
        }
        if (status === window.google.maps.GeocoderStatus.OK && has_zip) {
          resolve(g_zip)
        } else {
          resolve(false)
        }
      })
    })
  }

  const mapObj = (zip) => {
    return new Promise((resolve, reject) => {
      new window.google.maps.Geocoder().geocode({address: zip}, (res, status) => {
        const has_zip = res && res.find(item => item.address_components.find( x => x.types.includes("postal_code")) )
        if (status === window.google.maps.GeocoderStatus.OK && has_zip) {
          resolve(has_zip)
        } else {
          resolve({fail: true})
        }
      })
    })
  }

  const request = () => {
    return new Promise((resolve, reject) => {
      if (!('geolocation' in navigator)) {
        resolve(false)
      }
      navigator.geolocation.getCurrentPosition(
        position => resolve(position.coords),
        error => resolve(false),
        {
          enableHighAccuracy: false,
          timeout: 5000,
          maximumAge: 0
        }
      )
    })
  }

  const doStoresSort = (items) => {
    return items.sort((a, b) => {
      if (a.distance === false && b.distance !== false) return 1;
      if (a.distance !== false && b.distance === false) return -1; 
      if (a.distance === false && b.distance === false) return 0;
      return a.distance - b.distance; 
    })
  }

  const doKora = async () => {
    if (typeof localStorage === "undefined") return
    const shiplog = localStorage.getItem('shiplog')
    const is_local = window.location.hostname.includes('localhost')

    if (navigator.userAgent.includes('bot')) return
    

    const get_cookie = (name) => {
      if (typeof document === 'undefined') return null
      const nameEQ = `${name}=`
      var ca = document.cookie.split(';')
      for(var i=0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1,c.length)
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length)
      }
      return null
    }

    const set_cookie = (name, value) => {
      if (typeof document === 'undefined') return
      let expires = ''
      let date = new Date()
      date.setTime(date.getTime() + (400 * 24 * 60 * 60 * 1000))
      expires = `; expires=${date.toUTCString()}`
      document.cookie = `${name}=${(value || '')}${expires}; path=/;${is_local ? '' : ' domain=buckmason.com'}`
    }

    const getDevice = () => {
      const touches = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
      // const mobileagent = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
      // const mobileagent = navigator.userAgent
      // ma: mobileagent
      const mobile_size = window.innerWidth < 820
      const tablet_size = window.innerWidth > 819
      return {
        tch: touches ? 1 : 0, 
        mo: mobile_size ? 1 : 0, 
        tab: (touches && tablet_size) ? 1 : 0
      }
    }

    const remove_cookie = (name) => {
      if (typeof document === 'undefined') return
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
    }

    const cust = !!localStorage.getItem('cu')
    const locl_data = localStorage.getItem('kra') && parseInt(localStorage.getItem('kra'))
    const cook = get_cookie('kra')
    const cook_data = cook && parseInt(cook)
    const stamptoday = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Los_Angeles' })).setHours(0, 0, 0, 0)
    
    const locl_today = locl_data && locl_data === stamptoday
    const cook_today = cook_data && cook_data === stamptoday
    const known_today = locl_today || cook_today
    const known_return = (cust || locl_data || cook_data) && !(locl_today || cook_today)
    const unknown = !(locl_data && cook_data)

    // if (shiplog) {
    //   console.log('stamptoday', stamptoday)
    //   console.log('locl_data', locl_data, 'locl_today', locl_today)
    //   console.log('cook_data', cook_data, 'cook_today', cook_today)
    //   console.log('known_today', known_today, 'known_return', known_return)
    //   console.log('unknown', unknown)
    // }

    if (known_today && locl_data === cook_data) return

    let device = getDevice()
    if (!device.tch && !device.mo && !device.tab) device = {d: 1}
    
    set_cookie('kra', stamptoday)
    localStorage.setItem('kra', stamptoday)
    if (known_today) return


    const user_types = {
      new: 0,
      returning: 1
    }

    let response = await fetch('https://api.ipify.org?format=json')
    response = await response.json()
    const ipAddress = response?.ip
    const refer = JSON.parse(get_cookie('re'))
    

    const obj = {
      ip: ipAddress,
      ut: user_types[unknown ? 'new' : 'returning'],
      dv: device
    }
    if (cust) {
      obj.cu = cust ? 1 : 0
      obj.ut = 1
    }
    // const obj = {
    //   p: window.location.pathname,
    //   r: document.referrer.includes('buckmason.com') ? '' : document.referrer,
    //   a: params.get('utm_campaign') || false,
    //   o: params.get('utm_content') || false,
    //   m: params.get('utm_medium') || false,
    //   s: params.get('utm_source') || false,
    //   t: params.get('utm_term') || false,
    //   d: Date.now
    // }
    if (refer && (refer?.r !== '' || !!refer?.a || !!refer?.o || !!refer?.m || !!refer?.s)) {
      delete refer.d
      obj.ref = refer
    }

    // 6100ce37 bm
    // 94e04e6a te

    const url = `https://in.kora.io/weqrfv/${is_local ? '94e04e6a' : '6100ce37'}`

    try {
      await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-Foo-Bar': 'jgp0Ru3nvlCV6vy21z'
        },
        body: JSON.stringify(obj)
      })
      .then(res => {
        if (res) return
      })
    } catch (error) {}
  }




  return { getGeo, mapObj, doStoresSort, doKora }
};

export default Geoloc;






